/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-worlds-first-smart-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-worlds-first-smart-hearing-aid",
    "aria-label": "the worlds first smart hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The World’s First \"Smart\" Hearing Aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "smartest hearing aid"), " on the market, the Widex Evoke, provides pristine sound quality while simultaneously adjusting to your current environment. Using SoundSense Adapt technology, this hearing aid can change its settings automatically because it “learns” as it goes. So, there’s no waiting for it to catch up with you when you’re out and about. With a free app that you can download on your smartphone, you can easily customize your hearing preferences. In addition, this device keeps getting smarter as you use it by learning from your hearing preferences in the moment and over time. And the best part is, it’s so small, nobody will even notice you’re wearing it."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "in-brief",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-brief",
    "aria-label": "in brief permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In Brief:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "World’s first “smart” hearing aid"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Learns as it goes"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Customized to your listening experience"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Filters out the noise and metallic din, for a natural sound"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Evoke or TONELINK apps make for ease of use"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Automatically adjusts to different situations and environments"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Optimal sound wherever you go"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "With multiple models available, Evoke hearing aids are discreet and slee"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "better-hearing-is-in-your-hands",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#better-hearing-is-in-your-hands",
    "aria-label": "better hearing is in your hands permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Better Hearing Is in Your Hands"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Widex Evoke hearing aid is the beginning of the smart hearing aid era. The Evoke app (exclusive to the F2 models) and the TONELINK app (compatible with all models) work in tandem with your Evoke hearing aid. Both apps allow you to personalize the way you hear with just a few taps on your phone’s screen. In other words, you can control how you want to hear, and where. With the Evoke, it’s easier than ever before to adjust the volume and control the direction in which you want to focus your attention on. It uses SmartSense Technology to automatically adjust in similar situations to your premade settings. With the app, there is no need to fiddle around behind your ear to change any of the device’s settings. The app itself is user friendly, easy to learn, and free to download on your ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/iphone/",
    className: "c-md-a"
  }, "iPhone"), " or Android device."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Filter out the Noise so You Can Focus on What You Want to HearYour Evoke hearing aid comes with a built-in Fluid Sound Controller. This cool, innovative feature allows the device to automatically adapt to your ever-changing sound environment. So whether you’re at home or out with friends, you can enjoy optimal, natural sound at all times. The Evoke’s technology also does a fantastic job of filtering out distracting background noise while delivering crystal-clear sound. The only thing you will hear are the sounds that are most important to you – without any ringing or feedback noise."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ease-of-use-and-ease-of-hearing-all-in-one",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ease-of-use-and-ease-of-hearing-all-in-one",
    "aria-label": "ease of use and ease of hearing all in one permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ease of Use and Ease of Hearing, All-In-One"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you use the app, or you let it adjust on its own, this hearing aid is user friendly. Instead of struggling to watch TV or understanding what your loved ones are saying, you can now get back to enjoying life and all of its sounds. If using an app on your smartphone seems a little too complicated for you, no worries. The app is loaded with lots of great instructional content that will make it even easier for you to navigate. The Evoke and all its amazing features make it the swiss army knife of hearing aids. Use it to not only hear better, but to also stream phone calls, music and more! The sound and comfort will be so good, you might just forget you’re actually wearing a hearing aid."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-style-just-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-style-just-for-you",
    "aria-label": "a style just for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A Style Just for You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are 10 models available and 3 colors to choose from. So, no matter your style and taste, there’s an Evoke for you. In addition, its discreet design makes it practically invisible to others when worn. It sits snugly behind your ear, completely out of everyone’s sight. Your family and friends won’t notice your hearing aid – but they will notice how much your hearing has improved."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-hearing-experts-are-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-hearing-experts-are-here-to-help",
    "aria-label": "our hearing experts are here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our Hearing Experts Are Here to Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re already experiencing some of the early signs of hearing loss, like turning the TV volume up too loud or asking people to repeat themselves, then the Evoke might be the solution your ears have been looking for. Get in touch with us today to speak with one of our hearing experts. We can answer all your questions, and help you decide if the Widex Evoke is right for you. Call us, or click the button below to check if you qualify for a 45 day risk-free trial."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Widex Evoke now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
